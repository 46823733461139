import React, { useState } from "react"
import styled from 'styled-components'
import { mq } from '@stylesheets/settings'
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/sections/video"
import Stage from "../components/sections/stage"
import About from "../components/sections/about"
import Gallery from "../components/sections/gallery"
import Share from "../components/sections/share"
import Footer from "../components/sections/footer"
import ShareImage from "../static/images/og-image.jpg"
import BadgeImage from "../static/icons/unhate-filter-cta.png"

const StyledBadge = styled.a`
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 150px;
  height: 150px;

  ${mq('m')} {
    width: 200px;
    height: 200px;    
    bottom: 10px;
    right: 15px;
  }

  display: block;
  &.hidden {
    display: none;
  }
`;

const IndexPage = ({ intl }) => {

  const [badgeStyle, setBadgeStyle] = useState('visible');
  
  useScrollPosition(
    ({ prevPos, currPos }) => {
      var d = document.documentElement;
      var offset = d.scrollTop + window.innerHeight;
      var height = d.offsetHeight;
      const isVisible = offset < height;
      setBadgeStyle(isVisible ? 'visible' : 'hidden');
    },
    [badgeStyle]
  );

  return (
    <>
      <Layout>
        <SEO 
          title={intl.formatMessage({ id: "title" })} 
          meta={[
            {
              property: 'og:image:width',
              content: '1190',
            },
            {
              property: 'og:image:height',
              content: '630',
            },
            { 
              property: 'og:image', 
              content: `https://unhate-women.com${ShareImage}`
            }
          ]}  />
        <Stage copy={'Eine Initiative gegen <span class="nobr">frauenverachtende Hate Speech</span><br/>und für mehr Respekt gegenüber Frauen.'}/>
        <About 
          headline={{regular: 'Die', bold: 'Initiative'}} 
          copy={
            'Gewalt gegen Frauen ist nicht immer nur physisch. ' + 
            'Auch Sprache kann Gewalt sein. Verbale Gewalt gegen Frauen ' + 
            'wird millionenfach gehört, geliked und gefeiert – und so ' + 
            'zum Teil unseres Alltags und unserer Sprache.' +
            '<br/><br/>' +
            '<strong>#UNHATEWOMEN</strong> macht diese Gewalt gegen Frauen sichtbar.' +
            '<br/><br/>'+
            'Verbreite <strong>#UNHATEWOMEN</strong> und poste den ' + 
            'Hashtag unter frauenverachtende Texte, Songs oder Posts. ' + 
            'Bring die Gewalt zur Sprache und sensibilisiere dafür, dass ' + 
            'auch Worte Gewalt sein können. Damit Hassrede ' + 
            'gegen Frauen nicht weiter unwidersprochen bleibt und zur Normalität wird.' +
            '<br/><br/>' +
            'TERRE DES FEMMES fordert die konsequente Bekämpfung und Strafverfolgung ' + 
            'von Hasskriminalität gegen Frauen und Mädchen im Internet.'
          }/>
        <Video headline={{regular: 'Video', bold: 'ansehen'}} copy={'Gewalt gegen Frauen – gelesen von Frauen.'}/>
        <About 
          headline={{regular: 'Mach', bold: 'mit'}} 
          copy={
            'Verbreite die Motive oder den Hashtag <strong>#UNHATEWOMEN</strong> ' + 
            'in deinen Kanälen und bekämpfe so verbale Gewalt gegen Frauen.'
          }/>
        <Gallery />
        <About 
          copy={
            'Alle Motive zeigen echte Zitate aus Songs erfolgreicher deutscher Rapper.'
          }/>
        <Share />
        <Footer />
        <div>
        <StyledBadge className={badgeStyle} href="https://lvdb.me/unhatewomen" target="_blank" rel="noopener nofollow">
          <img style={{width: '100%', height: '100%'}} src={BadgeImage}/>
        </StyledBadge>
        </div>
      </Layout>
    </>
  );
};

export default injectIntl(IndexPage)
