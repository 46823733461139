import React from 'react';
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "styled-components"
import { getColor, mq } from '@stylesheets/settings'
import TDFLogo from '../../static/images/TDFLogo_big.svg'

import { 
  IconFacebook, 
  IconTwitter, 
  IconYoutube, 
  IconInstagram, 
  IconDonate, 
  IconContact 
} from '../../components/elements/icon'

const StyledWrapper = styled.div`
  display: flex;
  height: 250px;
  background: ${getColor('black')};
  padding: 20px 10%;
  flex-flow: column wrap;
  justify-content: space-between;


  ${mq('m')} {
    height: 200px;
    padding: 20px 10% 40px 10%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60px;

  ${mq('m', 'max')} {
    align-items: center;
  }
`;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px; 
  height: 24px;

  img {
    user-select: none;
    user-drag: none;
  }
`;

const PortalLinks = styled.div`
  display: flex; 
  width: 150px; 
  margin-top: 0; 
  justify-content: space-between;

  ${mq('m')} {
    margin-top: 30px; 
  }
`;

const StyledTDFLogo = styled.img`
  height: 70px;
  margin-bottom: 20px;

  ${mq('m')} {
    height: 80px;
  }
`;

const Footer = () => {
  return (
    <StyledWrapper>
      <Column direction={'left'}>
        <a href="https://www.frauenrechte.de" target="_blank" rel="noopener noreferrer">
          <StyledTDFLogo src={TDFLogo}/>
        </a>
        <div style={{display: 'flex', width: '200px', justifyContent: 'space-between'}}>
          <a href="https://www.frauenrechte.de/spenden/spendenmoeglichkeiten/online-spenden?" target="_blank" rel="noopener noreferrer" style={{color: 'white', fontSize: '14px', height: '20px'}}>
            <IconDonate fill={'white'} width={'25px'} height={'25px'} style={{marginRight: '10px'}} />Spenden</a>
          <a href="https://www.frauenrechte.de/kontakt" target="_blank" rel="noopener noreferrer" style={{color: 'white', fontSize: '14px', height: '20px'}}>
            <IconContact fill={'white'} width={'25px'} height={'25px'} style={{marginRight: '10px'}} />Kontakt</a>
        </div>
      </Column>
      <Column direction={'right'}>
        <PortalLinks>
          <SocialLink href="https://www.facebook.com/terre.des.femmes" target="_blank" rel="noopener">
            <IconFacebook fill={'white'} width={'25px'} height={'25px'} />
          </SocialLink>
          <SocialLink href="https://twitter.com/tdfev" target="_blank" rel="noopener noreferrer">
            <IconTwitter fill={'white'} width={'25px'} height={'25px'} />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/user/TERREDESFEMMES" target="_blank" rel="noopener noreferrer">
            <IconYoutube fill={'white'} width={'25px'} height={'25px'} />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/terre.des.femmes/" target="_blank" rel="noopener noreferrer">
            <IconInstagram fill={'white'} width={'25px'} height={'25px'} />
          </SocialLink>
        </PortalLinks>
        <div style={{display: 'flex', width: '170px', justifyContent: 'space-between'}}>
          <Link to="/imprint" style={{color: 'white', fontSize: '14px'}}>Impressum</Link>
          <Link to="/terms" style={{color: 'white', fontSize: '14px'}}>Datenschutz</Link>
        </div>
      </Column>
    </StyledWrapper>
  );
}

export default Footer;