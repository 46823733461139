import React from 'react';
import styled from "styled-components"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { getGridSpans, getColor, mq } from '@stylesheets/settings'
import { IconFacebook, IconTwitter, IconContact } from '../elements/icon'

import MailIcon from '../../static/icons/mail-icon.png'

import Headline from '../elements/headline'

const StyledWrapper = styled.div`
  ${getGridSpans('width', { s: 6, m: 6, l: 12 })}
  padding: 50px 20px;
  margin: 0 auto;
  text-align: center;
  background-color: ${getColor('yellow')}
`;

const ContentWrapper = styled.div`
  display: flex; 
  height: 100%;
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  
  ${mq('m', 'max')} {
    flex-flow: column wrap;
  }
`;

const ShareButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px; 
  height: 53px; 
  border: 2px solid black;

  img {
    user-select: none;
    user-drag: none;
  }
`;

const Share = () => {
  return (
    <StyledWrapper>
      <ContentWrapper>
        <Headline variant={'black'} regularText={'Initiative'} highlightText={'teilen'} />
        <div style={{display: 'flex', width: '200px', justifyContent: 'space-around'}}>
          <ShareButton rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent('https://unhate-women.com/de/')}`}>
            <IconFacebook fill={'black'} width={'25px'} height={'25px'} />
          </ShareButton>
          <ShareButton rel="noopener noreferrer" target="_blank" href={`https://twitter.com/intent/tweet?text=Bring verbale Gewalt gegen Frauen zur Sprache!&url=${encodeURIComponent('https://unhate-women.com/de/')}`}>
            <IconTwitter fill={'black'} width={'25px'} height={'25px'} />
          </ShareButton>
          <ShareButton rel="noopener noreferrer" target="_blank" href="mailto:?subject=#UNHATEWOMEN&body=Bring verbale Gewalt gegen Frauen zur Sprache!%0D%0A%0D%0Ahttps://unhate-women.com">
            <IconContact fill={'black'} width={'25px'} height={'25px'} />
          </ShareButton>
        </div>
      </ContentWrapper>
    </StyledWrapper>
  );
}

export default Share;