import React from 'react'
import PropTypes from 'prop-types'

import { getColor } from '@stylesheets/settings'

const IconFacebook = ({fill, width, height}) => (
  <svg enableBackground="new 0 0 13 22" viewBox="0 0 13 22" fill={getColor(fill)} width={width} height={height}
    xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m12.1 0h-3.3c-3.038 0-5.5 2.462-5.5 5.5v3.3h-3.3v4.4h3.3v8.8h4.4v-8.8h3.3l1.1-4.4h-4.4v-3.3c0-.608.492-1.1 1.1-1.1h3.3z" fill={getColor(fill)} fillRule="evenodd"/></svg>
);

const IconTwitter = ({fill, width, height}) => (
  <svg enableBackground="new 0 0 25 20" viewBox="0 0 25 20" fill={getColor(fill)} width={width} height={height}
    xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m24.494.15c-1.066.745-2.247 1.315-3.496 1.687-1.373-1.564-3.587-2.113-5.542-1.374s-3.237 2.609-3.209 4.682v1.103c-3.974.103-7.736-1.773-10.02-4.995 0 0-4.453 9.925 5.567 14.336-2.293 1.542-5.025 2.315-7.794 2.205 10.02 5.514 22.267 0 22.267-12.682-.001-.307-.031-.614-.089-.915 1.136-1.109 1.938-2.511 2.316-4.047z" fill={getColor(fill)} fillRule="evenodd"/></svg>
);

const IconInstagram = ({fill, width, height}) => (
  <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={getColor(fill)} width={width} height={height}
    xmlns="http://www.w3.org/2000/svg"><path d="m18 24h-12c-3.309 0-6-2.691-6-6v-12c0-3.309 2.691-6 6-6h12c3.309 0 6 2.691 6 6v12c0 3.309-2.691 6-6 6zm-12-22c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h12c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zm6.045 15.354c-1.399 0-2.778-.545-3.816-1.584-1.65-1.65-2.054-4.16-1.005-6.246 1.05-2.085 3.308-3.257 5.616-2.914 2.37.351 4.198 2.179 4.55 4.549.343 2.309-.829 4.566-2.915 5.616-.774.39-1.605.579-2.43.579zm-.001-8.802c-1.271 0-2.449.71-3.034 1.872-.66 1.313-.406 2.894.633 3.933 1.039 1.04 2.618 1.294 3.933.633 1.313-.66 2.051-2.082 1.835-3.536-.222-1.492-1.373-2.643-2.865-2.864-.168-.026-.336-.038-.502-.038z" fill={getColor(fill)}/></svg>
);

const IconYoutube = ({fill, width, height}) => (
  <svg enableBackground="new 0 0 28 20" viewBox="0 0 28 20" fill={getColor(fill)} width={width} height={height}
    xmlns="http://www.w3.org/2000/svg"><path d="m14 20c-.845 0-8.32-.016-10.417-.568-1.443-.389-2.59-1.51-2.999-2.926-.408-2.2-.597-4.351-.583-6.489-.011-2.11.178-4.245.56-6.333.387-1.534 1.534-2.701 3.007-3.112 2.112-.556 9.587-.572 10.432-.572.844 0 8.31.015 10.396.517 1.508.42 2.655 1.586 3.028 3.054.006.022.011.044.015.066.386 2.104.573 4.254.56 6.393.008 2.096-.18 4.214-.56 6.286-.388 1.534-1.534 2.701-3.008 3.112-2.11.556-9.585.572-10.431.572zm0-18c-2.226 0-8.344.09-9.908.502-.772.216-1.382.833-1.578 1.608-.346 1.901-.523 3.911-.514 5.909-.013 2.024.165 4.049.527 6.029.193.651.803 1.245 1.569 1.451 1.56.41 7.678.501 9.904.501s8.345-.091 9.908-.502c.771-.216 1.382-.834 1.579-1.608.342-1.885.52-3.879.512-5.862.013-2.012-.162-4.024-.52-5.994-.206-.761-.811-1.366-1.585-1.582-1.537-.369-7.665-.452-9.894-.452zm-2.167 11.96 6.5-3.96-6.5-3.96z" fill={getColor(fill)}/></svg>
);

const IconClose = ({fill, width, height}) => (
  <svg enableBackground="new 0 0 14.9 14.9" viewBox="0 0 14.9 14.9" fill={getColor(fill)} width={width} height={height}
    xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="m.7 0 14.2 14.2-.7.7-14.2-14.2z"/><path fill="#000" d="m14.9.7-14.2 14.2-.7-.7 14.2-14.2z"/></svg>
);

const IconDonate = ({fill, width, height, style}) => (
  <svg enableBackground="new 0 0 26 22" viewBox="0 0 26 22" 
  xmlns="http://www.w3.org/2000/svg" style={style} fill={getColor(fill)} width={width} height={height}>
    <path d="m18.911 2c1.207 0 2.342.47 3.195 1.324.853.853 1.324 1.988 1.324 3.195s-.47 2.342-1.324 3.195l-9.341 9.34-9.341-9.34c-.853-.854-1.323-1.988-1.323-3.195s.47-2.342 1.324-3.195c.853-.854 1.988-1.324 3.195-1.324s2.342.47 3.195 1.324l2.951 2.95 2.951-2.951c.853-.853 1.987-1.323 3.194-1.323m0-2c-1.668 0-3.336.636-4.609 1.909l-1.537 1.536-1.536-1.536c-1.273-1.272-2.941-1.909-4.609-1.909s-3.337.637-4.61 1.909c-2.546 2.546-2.546 6.673 0 9.219l10.755 10.754 10.755-10.754c2.546-2.546 2.546-6.673 0-9.219-1.272-1.273-2.941-1.909-4.609-1.909z" fill="#fff"/></svg>
);

const IconContact = ({fill, width, height, style}) => (
  <svg enableBackground="new 0 0 24 18" viewBox="0 0 24 18" style={style} fill={getColor(fill)} width={width} height={height}
  xmlns="http://www.w3.org/2000/svg"><path d="m0 0v18h24v-18zm22 2v1.451l-10 6.363-10-6.363v-1.451zm-20 14v-10.178l10 6.364 10-6.364v10.178z" fill={getColor(fill)}/></svg>
);

export {
  IconFacebook,
  IconTwitter,
  IconInstagram,
  IconYoutube,
  IconClose,
  IconContact,
  IconDonate
};